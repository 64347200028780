

import React from 'react'
import Contact from '../../componentLayer/pages/Contact'
export const contactRouter = [
  {
    // path : "contact",
    index:true,
    element: <Contact />,
  },
  
];
