
import goingoffice from "../../../Assets/girls.png";
import { Link, useOutletContext } from "react-router-dom";
import It_services from "../../../Assets/IT_Servicesss.png";
import Data_migration from "../../../Assets/Data_migration.png";
import WareHouse from "../../../Assets/WareHouse (2).png";
import Incident_mangemenet from "../../../Assets/Incident_mangement.png";
import ERP_tool from "../../../Assets/ERP_tool.png";
import groupteam from "../../../Assets/blogimg3.png";
import { useScrollAnimation } from "../../../utils/Utils";
const AboutUs = () => {
  const { linkClicked } = useOutletContext();
  useScrollAnimation();
  return (
    <div>
      <section className="bg-[#efefef] ">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-7 xl:mx-40 mx-3 md:mx-6">
          <div className="col-span-1 xl:my-auto my-5 mx-9 order-2 lg:order-1">
            <h1>Company overview</h1>
            <h2>Insights you can act on</h2>
            <p>
              We are committed to making our story about you and your successful
              journey, serving as an insightful and resourceful partner.
            </p>
            {/* <p className="my-3 group hover:text-darkblue">
              <span className="transition-colors duration-300 group-hover:text-blue-500 text-[18px] font-semibold leading-[1.5]">
                Explore in your Geography
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-7 transition-transform duration-300 group-hover:translate-x-1 inline-block ml-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                />
              </svg>
            </p> */}
          </div>
          <div className="col-span-1 flex items-center justify-center order-1 lg:order-2 image_design ">
            <img
              src={goingoffice}
              alt="Kapil Technologies"
              className="w-full h-full"
            />
          </div>
        </div>
      </section>
      <section className=" text-center  border border-y-[#9ca3af] border-x-0">
        <div className=" my-9 flex  items-center justify-center">
          <ul className="inline">
            {["Manufacturing", "Corporate Governance", "ESG"].map(
              (text, index) => (
                <li
                  key={index}
                  className="inline-block mx-3 w-auto text-center mb-3"
                >
                  <span className="text-bluecolor border-[#4e8ecb] bg-transparent font-normal inline-block w-auto h-auto py-[3.5px] px-5 align-middle leading-[30px] rounded-full border transition-all duration-200 ease-linear">
                    {text}
                  </span>
                </li>
              )
            )}
          </ul>
        </div>
      </section>
      <section className="py-10 bg-[#efefef]">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-10  xl:mx-40 mx-3 md:mx-6">
          <div className="col-span-1  order-2 lg:order-1 my-auto animate-up">
            <h2>About us</h2>
            <p className="text-paracolor">
              Founded in 1976, Kapil Technologies is among the largest IT and business
              consulting services firms in the world. We are insights-driven and
              outcomes-based to help accelerate returns on your investments.
            </p>
          </div>
          <div class="video-container">
            {/* <iframe
              width="520"
              height="315"
              src="https://www.youtube.com/embed/XZyEjhlJZgo?si=IEZpCZrN8gzXMENl"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe> */}
                 <iframe
    width="650"
    height="315"
    src="https://www.youtube.com/embed/oTIJunBa6MA?si=g5bXfPjjA7J__65l"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    referrerpolicy="strict-origin-when-cross-origin"
    allowfullscreen
  ></iframe>
          </div>
        </div>
      </section>
      <section className="py-10 bg-bluecolor">
        <h1 className="ps-10 xl:ps-36 text-white">Case Studies</h1>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-5 xl:mx-36 mx-3 md:mx-10">
          <div className="col-span-1 max-w-sm rounded overflow-hidden shadow-lg bg-[#fff] card-container animate-up flex flex-col">
            <div className="imagehover relative">
              <img
                src={Incident_mangemenet}
                alt="Incident_mangemenet"
                className="w-full"
              />
              <div className="overlay absolute inset-0 bg-[#fff] opacity-0 transition-opacity duration-300"></div>
            </div>
            <div className="px-6 py-4 flex-grow flex flex-col">
              <h3 className="pb-[12px] font-bold">
                Managed services in Infor enterprise solutions
              </h3>
              <Link
                className="hover-link transition-colors duration-300 flex-grow"
                to="casestudies"
                onClick={() => linkClicked()}
              >
                <p>
                  An industrial equipment manufacturer in GCC region has put
                  full trust on Kapil Tech for Managed services in Infor
                  enterprise solutions consecutive third year. Even complex
                  Incidents are resolved in record 2 hours timeline.
                </p>
              </Link>
              <p className="mt-5 mb-2 flex items-center gap-1 group hover:text-darkblue">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-7 transition-transform duration-300 group-hover:translate-x-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                  />
                </svg>
              </p>
            </div>
          </div>

          {/* Repeat similar structure for other cards */}
          <div className="col-span-1 max-w-sm rounded overflow-hidden shadow-lg bg-[#fff] card-container animate-up flex flex-col">
            <div className="imagehover relative">
              <img src={ERP_tool} alt="ERP_tool" className="w-full" />
              <div className="overlay absolute inset-0 bg-[#000] opacity-0 transition-opacity duration-300"></div>
            </div>
            <div className="px-6 py-4 flex-grow flex flex-col">
              <h3 className="pb-[12px] font-bold">
                Process integration with both Infor and third-party solutions
              </h3>
              <Link
                className="hover-link transition-colors duration-300 flex-grow"
                to="casestudies"
                onClick={() => linkClicked()}
              >
                <p>
                  Extensions are built in the standard ERP feature pack for
                  process integration with both Infor and third-party solutions.
                  Same extensions and integration components worked even after
                  solution upgrades.
                </p>
              </Link>
              <p className="mt-5 mb-2 flex items-center gap-1 group hover:text-darkblue">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-7 transition-transform duration-300 group-hover:translate-x-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                  />
                </svg>
              </p>
            </div>
          </div>

          <div className="col-span-1 max-w-sm rounded overflow-hidden shadow-lg bg-[#fff] card-container animate-up flex flex-col">
            <div className="imagehover relative">
              <img
                src={Data_migration}
                alt="Data_migration"
                className="w-full"
              />
              <div className="overlay absolute inset-0 bg-[#000] opacity-0 transition-opacity duration-300"></div>
            </div>
            <div className="px-6 py-4 flex-grow flex flex-col">
              <h3 className="pb-[12px] font-bold">
                Migration and retrofitting around 10 extended functionalities
              </h3>
              <Link
                className="hover-link transition-colors duration-300 flex-grow"
                to="casestudies"
                onClick={() => linkClicked()}
              >
                <p>
                  Successful migration and retrofitting around 10 extended
                  functionalities from older version to a later version of Infor
                  ERP for an Indian enterprise of exclusive business scenarios.
                </p>
              </Link>
              <p className="mt-5 mb-2 flex items-center gap-1 group hover:text-darkblue">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-7 transition-transform duration-300 group-hover:translate-x-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                  />
                </svg>
              </p>
            </div>
          </div>

          <div className="col-span-1 max-w-sm rounded overflow-hidden shadow-lg bg-[#fff] card-container animate-up flex flex-col">
            <div className="imagehover relative">
              <img src={WareHouse} alt="WareHouse" className="w-full" />
              <div className="overlay absolute inset-0 bg-[#000] opacity-0 transition-opacity duration-300"></div>
            </div>
            <div className="px-6 py-4 flex-grow flex flex-col">
              <h3 className="pb-[12px] font-bold">
                Warehouse management and Supplier collaboration
              </h3>
              <Link
                className="hover-link transition-colors duration-300 flex-grow"
                to="casestudies"
                onClick={() => linkClicked()}
              >
                <p>
                  Edge solutions for Warehouse management and Supplier
                  collaboration are deployed with custom BOD integrations.
                  Fulfilling Customer objective did lead to longer association
                  for support services.
                </p>
              </Link>
              <p className="mt-5 mb-2 flex items-center gap-1 group hover:text-darkblue">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-7 transition-transform duration-300 group-hover:translate-x-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                  />
                </svg>
              </p>
            </div>
          </div>
        </div>
      </section>
    
   
      <section className="py-10 bg-[#fff]">
       
        <div className="grid grid-col-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-5 xl:grid-cols-5 gap-9 xl:mx-40 mx-3 md:mx-10 ">
          <div className="col-span-2 animate-up">
            <div className="  rounded overflow-hidden shadow-lg bg-[#fff] card-container">
              <div className="imagehover relative">
                <img src={groupteam} alt="WareHouse" className="w-full" />
                <div className="overlay absolute inset-0 bg-[#000] opacity-0 transition-opacity duration-300"></div>
              </div>
              <div className="px-6 py-4">





              <h3 className="pb-[12px] font-bold">
              Warehouse management and Supplier collaboration
        </h3>
        <Link
          className="hover-link transition-colors duration-300 flex-grow"
          to="casestudies"
          onClick={() => linkClicked()}
        >
           <p>
                Edge solutions for Warehouse management and Supplier
                collaboration are deployed with custom BOD integrations.
                </p>
        </Link>
               
               
                <p className="my-10  flex items-center gap-1 group hover:text-darkblue">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="size-7 transition-transform duration-300 group-hover:translate-x-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                    />
                  </svg>
                </p>
              </div>
            </div>
          </div>
          <div className="col-span-3 animate-up">
            <div className=" flex lg:gap-5 ">
              <div className=" max-w-sm rounded overflow-hidden shadow-lg bg-[#fff] card-container">
                <div className="imagehover relative">
                  <img src={WareHouse} alt="WareHouse" className="w-full" />
                  <div className="overlay absolute inset-0 bg-[#000] opacity-0 transition-opacity duration-300"></div>
                </div>
                <div className="px-6 py-4">

                <h3 className="pb-[12px] font-bold">
              Warehouse management and Supplier collaboration
        </h3>
        <Link
          className="hover-link transition-colors duration-300 flex-grow"
          to="casestudies"
          onClick={() => linkClicked()}
        >
           <p>
                Edge solutions for Warehouse management and Supplier
                collaboration are deployed with custom BOD integrations.
                </p>
        </Link>




               
                  <p className="mt-5 mb-2 flex items-center gap-1 group hover:text-darkblue">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-7 transition-transform duration-300 group-hover:translate-x-1"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                      />
                    </svg>
                  </p>
                </div>
              </div>
              <div className=" max-w-sm rounded overflow-hidden shadow-lg bg-[#fff] card-container">
                <div className="imagehover relative">
                  <img src={It_services} alt="It_services" className="w-full" />
                  <div className="overlay absolute inset-0 bg-[#000] opacity-0 transition-opacity duration-300"></div>
                </div>
                <div className="px-6 py-4">
                <h3 className="pb-[12px] font-bold">
              Warehouse management and Supplier collaboration
        </h3>
        <Link
          className="hover-link transition-colors duration-300 flex-grow"
          to="casestudies"
          onClick={() => linkClicked()}
        >
           <p>
                Edge solutions for Warehouse management and Supplier
                collaboration are deployed with custom BOD integrations.
                </p>
        </Link>
               
                  <p className="mt-5 mb-2 flex items-center gap-1 group hover:text-darkblue">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-7 transition-transform duration-300 group-hover:translate-x-1"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                      />
                    </svg>
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-6 ps-10  bg-gradient-custom hover:bg-gradient-custom-reverse">
              <Link
                to="/learn-more"
                className="text-md text-[#fff] flex items-center "
              >
                <p className="mt-3 mb-2 flex items-center gap-1 group hover:text-darkblue py-1">
                  <span className="mr-2 hover:underline">Learn More</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="size-7 transition-transform duration-300 group-hover:translate-x-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                    />
                  </svg>
                </p>

                {/* <span className="text-3xl">&#8594;</span> */}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
