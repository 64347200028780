import infor_services from "../../../Assets/infor_services.png";
import infor_ERP from "../../../Assets/infor_ERP.png";
import infor_ERP_outcomes from "../../../Assets/ERP_outcomes.png";
import infor_consultancy from "../../../Assets/infor_consultancy.png";
import infor_barries from "../../../Assets/infor_barries.png";
import infor_colud_enterprise from "../../../Assets/Infor_enterprise.png";
import Image_ContentReusableComponent from "../../component/ReusablaeComponent/Image_ContentReusableComponent";
import HeaderSectionReusableComponent from "../../component/ReusablaeComponent/HeaderSectionReusableComponent";
import Content_GradientLine from "../../component/ReusablaeComponent/Content_GradientLine";
import ListOfLinkSection from "../../component/ReusablaeComponent/ListOfLinkSection";
import HeadingParagraphSection from "../../component/ReusablaeComponent/HeadingParagraphSection";
import PointsComponent from "../../component/ReusablaeComponent/PointsComponent";
const Infor = () => {
  const listItems = [
    {
      title: "Infor Cloudsuite CSI:",
      description:
        "Kapil Technologies has immense expertise in wide-ranging deployment of Infor CSI services across diverse industrial verticals. We are among the few global Infor partners that has the capability to accomplish a comprehensive implementation of Infor’s custom Cloudsuite CSI solutions for enterprises in expeditious timeframes. We are adept at all versions of the Infor Cloudsuite CSI platform.",
    },
    {
      title: "Infor M3 Cloudsuite:",
      description:
        "Our Infor M3 expertise cuts across various solutions that deal with cloud implementation, integrations, upgrades and migrations, regional and international rollouts, and managed services. With enriching skills and domain experience in Infor M3, we render our services across multiple industrial verticals.",
    },
    {
      title: "Infor XA (Formerly Mapics):",
      description:
        "Infor XA specialists at Kapil Technologies hold invaluable domain experience of the platform and help manufacturing enterprises achieve complete digital transformation of their operations. With this specialized commercial ERP software expertise, we have been driving impressive manufacturing-based business transformations across regions..",
    },
    {
      title: "Infor LN Cloudsuite (Formerly Baan):",
      description:
        "Our rich Infor LN skillets enable large manufacturers to streamline their production management, finances, logistics, and supply chain management. This platform is precision-driven and comes with high-level integration to give enterprises the capability and flexibility to manage operations at multiple manufacturing sites.",
    },
  ];
  const ListofLinks = [
    "Consulting Services",
    "Cloud Implementation Services",
    "Cloud-based Upgrade and Migration Services",
    "Infrastructure Management Services",
  ];
  return (
    <div>
      {/* Header */}
      <HeaderSectionReusableComponent
        mainHeading={"Leading-edge Infor Services"}
        heading={
          "Use Our Infor Expertise to Your Advantage. Automate and Streamline Business Processes Cost-effectively."
        }
        paragragh={
          <>
            Staying ahead of the competition demands your business to be
            innovative with futuristic technologies. Power your business
            transformation with new-age Infor technologies that automate and
            revolutionize the way enterprises operate, with expert consulting
            services by Kapil Technologies’ seasoned consultants.
          </>
        }
        imgScr={infor_services}
      />

      {/*Your Business Competitiveness Goes Several Notches Higher When You Collaborate With the Right Oracle Services Provider*/}
      <Image_ContentReusableComponent
        bgColor="#fff"
        heading={
          "Wide-ranging Infor ERP Solutions That Drive Your Enterprise Transformation"
        }
        paragraph={
          <>
       As a certified Infor partner, our extensive expertise on Infor’s ERP solutions covers <span className="font-bold">
       Infor
M3, Infor LN, Infor CloudSuite, Infor CRM, Infor Enterprise Asset Management
(EAM)</span>, and <span className="font-bold">Infor XA</span> among others. Our specialized <span className="font-bold">Infor ERP solutions</span> streamline
your operations, empowering you with effective decision-making that is data-driven.
Elevate your productivity manifold, manage operations seamlessly, and deliver
top-notch customer experiences with these solutions.
          </>
        }
        imgsrc={infor_ERP}
      />

      {/* Be driven with outcomes */}

      <PointsComponent
      title="Be Driven with Outcomes That Redefine Your Business"
      reverse={false}
      description="Our specialized Infor services are flexible and designed to help you achieve the best business outcomes. Leveraging our expertise with Infor’s ERP solutions enables your enterprise with the following:"
      listItems={[
        'Optimum Productivity',
        'Data-driven Decision Making',
        'Smooth Business Transitions',
        'Seamless Integration Between Systems and Applications',
        'Scalable Solutions',
      ]}
      imageSrc={infor_ERP_outcomes}
      altText="infor_ERP_outcomes"
    />
      {/* KT wide-ranging infor */}
      <ListOfLinkSection
        title="Kapil Technologies’ Wide-Ranging Infor Services Portfolio"
        ListofLinks={ListofLinks}
      />

      {/*  S Make The Best of Our Infor Cloud Expertise and Reinvent Your
              Enterprise*/}
      <Content_GradientLine
        title="Make The Best of Our Infor Cloud Expertise and Reinvent Your Enterprise"
        subHeading="Our Infor Cloud Offerings That Elevate Your Business"
        paragraph={
          <>
            Our Infor-based cloud solutions fast track your enterprise’s journey
            to value-realization. We help you deploy them for an end-to-end
            digital transformation of its business operations..
          </>
        }
        listItems={listItems}
        imgSrc={infor_colud_enterprise}
      />

      {/* Streamline Resources, Bootst productivity */}

      <HeadingParagraphSection
        heading={
          "Streamline Resources, Boost Productivity, and Hold the Competitive Edge"
        }
        paragraph={
          <>
            In the age of automation, it is vital to channel your resources to
            more productive tasks. Kapil Technologies empowers you to accomplish
            that by helping you streamline your resources for more productive
            tasks while automating the mundane and repetitive ones. This
            enhances your productivity and helps you hold a competitive edge in
            the industry with efficient business operations.
          </>
        }
      />

      {/* Experience infor consulting */}

      <Image_ContentReusableComponent
        bgColor="#fff"
        reverse={false}
        heading={
          "Experience Infor Consulting That’s Impactful and Makes a Difference"
        }
        paragraph={
          <>
            Unleash your best business capabilities with our top-tier Infor
            consulting services. Our proficient consultants fully absorb your
            business requirements and create custom-made strategies that
            simplify your complex daily business operations, bring
            cost-effectiveness, and enable growth that’s profitable and
            sustainable. With our expert consultants you can rest assured of
            close collaboration that fosters value-driven growth.
          </>
        }
        imgsrc={infor_consultancy}
      />

      {/* Break the barriers and explore limitless opportunities with KT */}

      <Image_ContentReusableComponent
        heading={
          "Break The Barriers and Explore Limitless Opportunities with Kapil Technologies’ Resourceful Technology and Business Acumen"
        }
        paragraph={
          <>
            No matter what the challenge, our ace consultants possess top-notch
            technology and business acumen to help your enterprise succeed with
            flawless execution of Infor technology deployments. Whether it is
            integration of systems and applications, cloud platform
            implementations and their upgrades and migrations, bespoke and agile
            solutions that cater to your organization, and training and support
            services that equip your workforce with the capability to leverage
            Infor technologies, our consultants will deliver on every front to
            help you realize your business goals faster.
          </>
        }
        imgsrc={infor_barries}
      />
    </div>
  );
};

export default Infor;
