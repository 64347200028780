import React, { useState } from "react";
import { useScrollAnimation } from "../../../utils/Utils";

import { Link, useOutletContext } from "react-router-dom";

const Webinar = () => {
  const [activeLink, setActiveLink] = useState("upcomingevents");
  const { linkClicked } = useOutletContext();
  console.log("activelink", activeLink);
  const handleNavLinkClick = (link) => {
    setActiveLink(link);
  };

  useScrollAnimation();
  return (
    <div>
      <section>
        <div className="webinar_main">
          <h1 className="uppercase text-white lg:ps-48 ps-12 animate-up">
            Webinar
          </h1>
        </div>
      </section>
      <section className="">
        <ul className="flex flex-wrap  justify-center font-medium text-center  border-b border-b-[#d1d5db] bg-[#efefef]">
          <li
            onClick={() => handleNavLinkClick("upcomingevents")}
            className={`cursor-pointer w-full sm:w-1/6 border-r border-r-black font-[500]`}
          >
            <div className="flex flex-col items-center">
              <span
                className={`text-[18px] py-4 px-2 ${
                  activeLink === "upcomingevents"
                    ? "text-darkblue"
                    : "text-[#333]"
                }`}
              >
                Upcoming Events
              </span>
            </div>
          </li>

          <li
            onClick={() => handleNavLinkClick("upcomingwebinar")}
            className={`cursor-pointer w-full sm:w-1/6 border-r border-r-black font-[500]`}
          >
            <div className="flex flex-col items-center">
              <span
                className={`text-[18px] py-4 px-2 ${
                  activeLink === "upcomingwebinar"
                    ? "text-darkblue"
                    : "text-[#333]"
                }`}
              >
                Upcoming Webinar
              </span>
            </div>
          </li>

          <li
            onClick={() => handleNavLinkClick("pastevents")}
            className={`cursor-pointer w-full sm:w-1/6 border-r border-r-black font-[500]`}
          >
            <div className="flex flex-col items-center">
              <span
                className={`text-[18px] py-4 px-2 ${
                  activeLink === "pastevents" ? "text-darkblue" : "text-[#333]"
                }`}
              >
                Past Events
              </span>
            </div>
          </li>

          <li
            onClick={() => handleNavLinkClick("watchnowwebinars")}
            className={`cursor-pointer w-full sm:w-1/6 border-r border-r-black font-[500]`}
          >
            <div className="flex flex-col items-center">
              <span
                className={`text-[18px] py-4 px-2 ${
                  activeLink === "watchnowwebinars"
                    ? "text-darkblue"
                    : "text-[#333]"
                }`}
              >
                Watch now Webinars
              </span>
            </div>
          </li>
        </ul>

        {/* Office Address Content Based on Active Link */}
        {activeLink === "upcomingevents" && (
          <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-x-4 lg:mx-40 md:mx-9 mx-3 py-8">
            <div className="col-span-2">
              <h2 className="text-[24px]">
                Meet Kapil Technologies @ 2024 Oracle CloudWorld
              </h2>
              <p className="text-[#949494]">
                Sep 09 - 12, 2024 | The Venetian, Las Vegas, NV
              </p>
              <p className="text-[#949494]">
                Transform your business with Oracle Cloud applications, trust
                the experts. Kapil Technologies is delighted to return to Oracle
                CloudWorld.
              </p>
              <Link to="events" onClick={() => linkClicked()}>
                <button className="border border-[#fff] rounded-md px-3 py-2 mt-5 text-[#fff] bg-bluecolor hover:bg-darkblue">
                  Read More
                </button>
              </Link>
            </div>
            <div className="col-span-1">
              <Link to="events" onClick={() => linkClicked()}>
                <button className="border border-[#fff] rounded-md px-3 py-2 mt-5 text-[#fff] bg-bluecolor hover:bg-darkblue">
                  Register Now
                </button>
              </Link>
            </div>
          </div>
        )}
        {activeLink === "upcomingwebinar" && (
          <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-x-4 lg:mx-40 md:mx-9 mx-3 py-8">
            <div className="col-span-2">
              <h2 className="text-[24px]">
                Meet Kapil Technologies @ 2024 Oracle CloudWorld
              </h2>
              <p className="text-[#949494]">
                Sep 09 - 12, 2024 | The Venetian, Las Vegas, NV
              </p>
              <p className="text-[#949494]">
                Transform your business with Oracle Cloud applications, trust
                the experts. Kapil Technologies is delighted to return to Oracle
                CloudWorld.
              </p>

              <Link to="events" onClick={() => linkClicked()}>
                <button className="border border-[#fff] rounded-md px-3 py-2 mt-5 text-[#fff] bg-bluecolor hover:bg-darkblue">
                  Read More
                </button>
              </Link>
            </div>
            <div className="col-span-1">
              <Link to="events" onClick={() => linkClicked()}>
                <button className="border border-[#fff] rounded-md px-3 py-2 mt-5 text-[#fff] bg-bluecolor hover:bg-darkblue">
                  Register Now
                </button>
              </Link>
            </div>
          </div>
        )}
        {activeLink === "pastevents" && (
          <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-x-4 lg:mx-40 md:mx-9 mx-3 py-8">
            <div className="col-span-2">
              <h2 className="text-[24px]">
                Meet Kapil Technologies @ 2024 Oracle CloudWorld
              </h2>
              <p className="text-[#949494]">
                Sep 09 - 12, 2024 | The Venetian, Las Vegas, NV
              </p>
              <p className="text-[#949494]">
                Transform your business with Oracle Cloud applications, trust
                the experts. Kapil Technologies is delighted to return to Oracle
                CloudWorld.
              </p>

              <Link to="events" onClick={() => linkClicked()}>
                <button className="border border-[#fff] rounded-md px-3 py-2 mt-5 text-[#fff] bg-bluecolor hover:bg-darkblue">
                  Read More
                </button>
              </Link>
            </div>
            <div className="col-span-1" onClick={() => linkClicked()}>
              <Link to="events">
                <button className="border border-[#fff] rounded-md px-3 py-2 mt-5 text-[#fff] bg-bluecolor hover:bg-darkblue">
                  Register Now
                </button>
              </Link>
            </div>
          </div>
        )}
        {activeLink === "watchnowwebinars" && (
          <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-x-4 lg:mx-40 md:mx-9 mx-3 py-8">
            <div className="col-span-2">
              <h2 className="text-[24px]">
                Meet Kapil Technologies @ 2024 Oracle CloudWorld
              </h2>
              <p className="text-[#949494]">
                Sep 09 - 12, 2024 | The Venetian, Las Vegas, NV
              </p>
              <p className="text-[#949494]">
                Transform your business with Oracle Cloud applications, trust
                the experts. Kapil Technologies is delighted to return to Oracle
                CloudWorld.
              </p>
              <Link to="events" onClick={() => linkClicked()}>
                <button className="border border-[#fff] rounded-md px-3 py-2 mt-5 text-[#fff] bg-bluecolor hover:bg-darkblue">
                  Read More
                </button>
              </Link>
            </div>
            <div className="col-span-1">
              <Link to="events" onClick={() => linkClicked()}>
                <button className="border border-[#fff] rounded-md px-3 py-2 mt-5 text-[#fff] bg-bluecolor hover:bg-darkblue">
                  Register Now
                </button>
              </Link>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default Webinar;
