import React from 'react'

const Content_GradientLine = ({title, subHeading, paragraph , imgSrc ,listItems}) => {
  return (
    <section className="bg-[#fff] py-10">
    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-5 xl:mx-40 mx-3 md:mx-6">
      <div className="col-span-1 animate-up">
        <h2 >
         {title}
        </h2>
        <p >
          {subHeading}
            </p>
        <p className="pb-3">
         {paragraph}
        </p>

        <img
          src={imgSrc}
          alt="imgSrc"
          className="animate-up "
        />
      </div>
      <div className="col-span-1 animate-up   relative pl-14">
        <dl>
          <ul>
            {listItems.map((item, index) => (
              <li
                key={index}
                className="list-disc text-base leading-[1.25] mb-3.5 custom-justify"
              >
                <strong className="font-semibold text-md">
                  {item.title}
                </strong>
                &nbsp;
                <span>{item.description}</span>
              </li>
            ))}
          </ul>
        </dl>
      </div>
    </div>
  </section>
  )
}

export default Content_GradientLine