import React from "react";
import Career from "../../componentLayer/pages/career/Career.jsx";
import Description from "../../componentLayer/pages/career/Description.jsx";

export const CareerRouter = [
  // {
  //   // path : "contact",
  //   index:true,
  //   element: <Career />,
  // },

  {
 
    children: [
      { index: true, element: <Career /> },
      { path: "position_description", element: <Description/> },
    ],
  },
];
