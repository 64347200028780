import { useScrollAnimation } from "../../../utils/Utils";

const PointsComponent = ({ title, description, listItems, imageSrc, altText , reverse=true, bgColor = '#fff'}) => {
    useScrollAnimation();
  return (
    <section className="py-10" style={{ backgroundColor: bgColor }}>
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:mx-36 mx-3 md:mx-10 gap-10  ">
      <div className={`col-span-1 order-2 ${reverse ? 'lg:order-2' : 'lg:order-1'} animate-up my-auto`}>
        <h2>{title}</h2>
        <p className="mb-4 text-paracolor animate-up">
          {description}
          <ul className="list-none pt-4">
            {listItems.map((item, index) => (
              <li
                key={index}
                className="before:content-['\2192'] before:mr-2"
              >
                {item}
              </li>
            ))}
          </ul>
        </p>
      </div>
      <img
        src={imageSrc}
        alt={altText}
        className={`w-full h-full order-1 ${reverse ? 'lg:order-1' : 'lg:order-2'} animate-up`}
      />
    </div>
  </section>
  )
}

export default PointsComponent