import React from "react";
import aws from "../../../Assets/aws.svg";
import actifio from "../../../Assets/actifio.svg";
import { Link } from "react-router-dom";
import { useScrollAnimation } from "../../../utils/Utils";

const Partner = () => {
  useScrollAnimation();
  return (
    <div>
      <section className="py-10">
        <div className="partner_main">
          <h1 className="uppercase text-white  ps-20 animate-up">
            Alliances and Partnerships
          </h1>
        </div>
        <p className=" xl:mx-96 lg:mx-28 mx-3 md:mx-16 py-8 animate-up">
          As an organization, we regularly invest in global partnerships that
          support the co-development of solutions that enhance our services. Our
          Advise-to-Execute philosophy is the bedrock of our innovative
          capabilities and through collaborative efforts with our partners, we
          help customers accelerate their digital and cloud transformation
          journeys.
        </p>

        <div className="grid grid-col-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-7 xl:mx-40 mx-3 md:mx-10">
          <div class="border border-[#cbd5e1]  shadow dark:bg-gray-800 dark:border-gray-700 p-6 animate-up ">
            <img
              class=" w-20 pb-4 rounded-t-lg"
              src={aws}
              alt="product image"
            />
            <hr className="text-[#cbd5e1]" />

            <p className="py-3">
              Leveraging AWS to build solutions and services for customers.
            </p>
{/* 
            <Link href="#">
              <h5 class="text-lg font-semibold tracking-tight text-bluecolor dark:text-white">
                Know more
              </h5>
            </Link> */}
          </div>
          <div class="border border-[#cbd5e1]  shadow dark:bg-gray-800 dark:border-gray-700 p-6 animate-up">
            <img
              class=" w-20 pb-4 rounded-t-lg"
              src={aws}
              alt="product image"
            />
            <hr className="text-[#cbd5e1]" />

            <p className="py-3">
              Leveraging AWS to build solutions and services for customers.
            </p>

            {/* <Link href="#">
              <h5 class="text-lg font-semibold tracking-tight text-bluecolor dark:text-white">
                Know more
              </h5>
            </Link> */}
          </div>
          <div class="border border-[#cbd5e1]  shadow dark:bg-gray-800 dark:border-gray-700 p-6 animate-up">
            <img
              class=" w-20 pb-4 rounded-t-lg"
              src={aws}
              alt="product image"
            />
            <hr className="text-[#cbd5e1]" />

            <p className="py-3">
              Leveraging AWS to build solutions and services for customers.
            </p>

            {/* <Link href="#">
              <h5 class="text-lg font-semibold tracking-tight text-bluecolor dark:text-white">
                Know more
              </h5>
            </Link> */}
          </div>
          <div class="border border-[#cbd5e1]  shadow dark:bg-gray-800 dark:border-gray-700 p-6 animate-up">
            <img
              class=" w-20 pb-4 rounded-t-lg"
              src={aws}
              alt="product image"
            />
            <hr className="text-[#cbd5e1]" />

            <p className="py-3">
              Leveraging AWS to build solutions and services for customers.
            </p>

            {/* <Link href="#">
              <h5 class="text-lg font-semibold tracking-tight text-bluecolor dark:text-white">
                Know more
              </h5>
            </Link> */}
          </div>
          <div class="border border-[#cbd5e1]  shadow dark:bg-gray-800 dark:border-gray-700 p-6 animate-up">
            <img
              class=" w-20 pb-4 rounded-t-lg"
              src={aws}
              alt="product image"
            />
            <hr className="text-[#cbd5e1]" />

            <p className="py-3">
              Leveraging AWS to build solutions and services for customers.
            </p>

            {/* <Link href="#">
              <h5 class="text-lg font-semibold tracking-tight text-bluecolor dark:text-white">
                Know more
              </h5>
            </Link> */}
          </div>
          <div class="border border-[#cbd5e1]  shadow dark:bg-gray-800 dark:border-gray-700 p-6 animate-up">
            <img
              class=" w-20 pb-4 rounded-t-lg"
              src={aws}
              alt="product image"
            />
            <hr className="text-[#cbd5e1]" />

            <p className="py-3">
              Leveraging AWS to build solutions and services for customers.
            </p>

            {/* <Link href="#">
              <h5 class="text-lg font-semibold tracking-tight text-bluecolor dark:text-white">
                Know more
              </h5>
            </Link> */}
          </div>
          <div class="border border-[#cbd5e1]  shadow dark:bg-gray-800 dark:border-gray-700 p-6 animate-up">
            <img
              class=" w-20 pb-4 rounded-t-lg"
              src={aws}
              alt="product image"
            />
            <hr className="text-[#cbd5e1]" />

            <p className="py-3">
              Leveraging AWS to build solutions and services for customers.
            </p>

            {/* <Link href="#">
              <h5 class="text-lg font-semibold tracking-tight text-bluecolor dark:text-white">
                Know more
              </h5>
            </Link> */}
          </div>
        </div>
      </section>

      <section className="py-10 bg-[#f5f5f5]">
        <div className="grid grid-col-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-7 xl:mx-52 mx-3 md:mx-10">
          <div className="col-span-1 bg-[#f9f9f9] shadow-sm rounded-md animate-up">
            <img src={actifio} alt="actifio" className="p-10" />
          </div>
          <div className="col-span-1 bg-[#f9f9f9] shadow-sm rounded-md animate-up">
            <img src={actifio} alt="actifio" className="p-10" />
          </div>
          <div className="col-span-1 bg-[#f9f9f9] shadow-sm rounded-md animate-up">
            <img src={actifio} alt="actifio" className="p-10" />
          </div>
          <div className="col-span-1 bg-[#f9f9f9] shadow-sm rounded-md animate-up">
            <img src={actifio} alt="actifio" className="p-10" />
          </div>
          <div className="col-span-1 bg-[#f9f9f9] shadow-sm rounded-md animate-up">
            <img src={actifio} alt="actifio" className="p-10" />
          </div>
          <div className="col-span-1 bg-[#f9f9f9] shadow-sm rounded-md animate-up">
            <img src={actifio} alt="actifio" className="p-10" />
          </div>
          <div className="col-span-1 bg-[#f9f9f9] shadow-sm rounded-md animate-up">
            <img src={actifio} alt="actifio" className="p-10" />
          </div>
          <div className="col-span-1 bg-[#f9f9f9] shadow-sm rounded-md animate-up">
            <img src={actifio} alt="actifio" className="p-10" />
          </div>
          <div className="col-span-1 bg-[#f9f9f9] shadow-sm rounded-md animate-up">
            <img src={actifio} alt="actifio" className="p-10" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Partner;
