import React from "react";
import Kapil_Technologies_logo from "../../../Assets/Kapil Technlogies Logo.png";
import { useScrollAnimation } from "../../../utils/Utils";
import { Link } from "react-router-dom";

const Footer = ({linkClicked}) => {
  
  useScrollAnimation();
  return (
    <div>
      {/* Help Section */}
      <section className="bg-bluecolor p-8 text-center">
        <h1 className="text-[#f5f5f5] text-3xl font-bold animate-up text-center">
          How can we Help?
        </h1>
        <p className="text-[#f5f5f5] my-4 animate-up text-center">
          If you have any questions or need assistance, please reach out to us.
        </p>
        <Link to="/contact"  onClick={()=>{linkClicked()}} className="">
          <button className="border border-[#fff] rounded-full px-5 py-2 text-white animate-up hover:bg-white hover:text-black transition-colors duration-300">
            Click Here
          </button>
        </Link>
      </section>

      {/* Footer Section */}
      <footer className="bg-[#efefef]">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 xl:mx-36 mx-3 md:mx-10">
          {/* Company Info */}
          <div className="bg-white p-4">
            <img src={Kapil_Technologies_logo} alt="Kapil Technologies Logo" className="w-48 h-auto" />
            <p className="text-xs leading-relaxed text-[#525252] pt-5">
              Kapil Technologies is a subsidiary of the prestigious Kapil Group, a dynamic corporate conglomerate founded in 1981. The Group’s services span Financial Services, Fintech, IT, Drone Technology, Construction, Solar Power, Media, Foods and Beverages, Healthcare, and more.
            </p>
            <Link to="/insights/aboutus" className="my-2 flex items-center gap-1 group hover:text-darkblue"  
           >
              <span className="transition-colors duration-300 group-hover:text-blue-500 lg:text-md" onClick={()=>{linkClicked()}}>
                Learn About Kapil Technologies
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5 transition-transform duration-300 group-hover:translate-x-1"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
              </svg>
            </Link>
          </div>

          {/* Company Links */}
          <div className="p-4">
            <h2 className="text-lg font-semibold mb-4">Company</h2>
            <ul>
              <li className="py-1">
                <Link to="/insights/aboutus" className="text-sm hover:text-bluecolor"  onClick={()=>{linkClicked()}}>About Us</Link>
              </li>
              <li className="text-sm py-1">Country Sites</li>
              <li className="text-sm py-1">ESG</li>
              <li className="text-sm py-1">Investors</li>
              <li className="text-sm py-1">Newsroom</li>
              <li className="text-sm py-1">Partner Ecosystem</li>
            </ul>
          </div>

          {/* Articles Section */}
          <div className="p-4">
            <h2 className="text-lg font-semibold mb-4">Articles</h2>
            <ul>
              <li className="text-sm py-1">Articles</li>
              <li className="py-1">
                <Link to="/insights/blog" className="text-sm hover:text-bluecolor" onClick={()=>{linkClicked()}}>Blog</Link>
              </li>
              <li className="py-1">
                <Link to="/casestudies" className="text-sm hover:text-bluecolor" onClick={()=>{linkClicked()}}>Case Studies</Link>
              </li>
              <li className="text-sm py-1 ">
                <Link to="/insights/webinar" className="text-sm hover:text-bluecolor" onClick={()=>{linkClicked()}}>Events</Link>
              </li>
              <li className="text-sm py-1">Podcasts</li>
              <li className="text-sm py-1">White Papers</li>
            </ul>
          </div>

          {/* Support Section */}
          <div className="p-4">
            <h2 className="text-lg font-semibold mb-4">Support</h2>
            <ul>
              <li className="text-sm py-1">Accessibility</li>
              <li className="text-sm py-1">Cookie Management</li>
              <li className="text-sm py-1">Privacy</li>
              <li className="text-sm py-1">Terms of Use</li>
              {/* <li className="py-1">
                <Link to="/career" className="text-sm hover:text-bluecolor">Careers</Link>
              </li> */}
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
