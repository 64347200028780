import Manufacturing from "../../componentLayer/pages/industries/Manufacturing";



export const industriesRouter = [
    {
        path : "automotive",
        element: <Manufacturing />,
      },
      {
        path : "construction",
        element: <Manufacturing />,
      },
      {
        path : "energy",
        element: <Manufacturing />,
      },
      {
        path : "euipment",
        element: <Manufacturing />,
      },
      {
        path : "food_and_beverage",
        element: <Manufacturing />,
      },
      {
        path : "industrial_macinery_and_equipment",
        element: <Manufacturing />,
      },
      {
        path : "industrial_manufacturing",
        element: <Manufacturing />,
      },
      {
        path : "life_sciences",
        element: <Manufacturing />,
      },
      {
        path : "high_tech_and_electrinics",
        element: <Manufacturing />,
      },
    

     

  // {
  //   path:"contact",
  //   index: true,
  //   element: <Contact />,
  // },
 
 
];