import React, { useState } from "react";
import america from "../../Assets/america.png";
import europe from "../../Assets/europe.png";
import asia_pacific from "../../Assets/asia-pacific.png";
import middle_east from "../../Assets/middle-east-turkey-and-africa.png";
import { useScrollAnimation } from "../../utils/Utils";
import { Link } from "react-router-dom";

const Contact = () => {
  const [formData, setFormData] = useState({
    fullname: "",
    company: "",
    workemail: "",
    country: "",
    countrycode: "",
    mobilenumber: "",
    nicheindustry: "",
    about: "",
    agreement: false,
  });
  
  const [errors, setErrors] = useState({});
  
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  
    // Validate the input field while typing
    validateField(name, value, type === "checkbox" ? checked : value);
  };
  
  // Field validation for live error checking
  const validateField = (name, value, checked) => {
    let error = "";
  
    switch (name) {
      case "fullname":
        if (!value) error = "Full Name is required";
        break;
  
      case "company":
        if (!value) error = "Company Name is required";
        break;
  
      case "workemail":
        if (!value) {
          error = "Work Email is required";
        } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          error = "Email address is invalid";
        }
        break;
  
      case "country":
        if (!value) error = "Country is required";
        break;
  
      case "countrycode":
        if (!value) error = "Country Code is required";
        break;
  
      case "mobilenumber":
        if (!value) {
          error = "Mobile Number is required";
        } else if (!/^\d+$/.test(value)) {
          error = "Mobile Number must contain only digits";
        } else if (value.length !== 10) {
          error = "Mobile Number must be exactly 10 digits";
        }
        break;
  
      case "nicheindustry":
        if (!value) error = "Niche Industry is required";
        break;
  
      case "about":
        if (!value) error = "Please tell us how we can help you";
        break;
  
      case "agreement":
        if (!checked) error = "You must accept the agreement";
        break;
  
      default:
        break;
    }
  
    setErrors((prevErrors) => {
      if (error) {
        return { ...prevErrors, [name]: error }; // Set error if validation fails
      } else {
        const { [name]: removedError, ...rest } = prevErrors; // Remove error if validation passes
        return rest;
      }
    });
  };
  
  // Validation for all fields on submit
  const validate = () => {
    let formErrors = {};
  
    // Full Name Validation
    if (!formData.fullname) formErrors.fullname = "Full Name is required";
  
    // Company Validation
    if (!formData.company) formErrors.company = "Company Name is required";
  
    // Work Email Validation
    if (!formData.workemail) {
      formErrors.workemail = "Work Email is required";
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.workemail)) {
      formErrors.workemail = "Email address is invalid";
    }
  
    // Country Validation
    if (!formData.country) formErrors.country = "Country is required";
  
    // Country Code Validation
    if (!formData.countrycode) formErrors.countrycode = "Country Code is required";
  
    // Mobile Number Validation
    if (!formData.mobilenumber) {
      formErrors.mobilenumber = "Mobile Number is required";
    } else if (!/^\d+$/.test(formData.mobilenumber)) {
      formErrors.mobilenumber = "Mobile Number must contain only digits";
    } else if (formData.mobilenumber.length !== 10) {
      formErrors.mobilenumber = "Mobile Number must be exactly 10 digits";
    }
  
    // Niche Industry Validation
    if (!formData.nicheindustry)
      formErrors.nicheindustry = "Niche Industry is required";
  
    // About Validation
    if (!formData.about)
      formErrors.about = "Please tell us how we can help you";
  
    // Agreement Validation
    if (!formData.agreement)
      formErrors.agreement = "You must accept the agreement";
  
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      // Form submission logic
      console.log("Form data submitted:", formData);
    } else {
      console.log("Validation errors:", errors);
    }
  };

  const [activeLink, setActiveLink] = useState("america");
  console.log("activelink", activeLink);
  const handleNavLinkClick = (link) => {
    setActiveLink(link);
  };

  const officeaddressesamerica = [
    {
      country: "United States",
      company: "KCS Technologies Inc",
      address: `1603 Capitol Ave. Suite #310,Cheyenne,`,
      pincode: "Wyoming - 820010",
      email: "info@kcs-tech.com",
    },
    {
      country: "USA",
      company: "Relevant Solution",
      address: `Suite #310, 1603 Capitol Ave, Cheyenne,`,
      pincode: "Wyoming - 820010",
      email: "info@kcs-tech.com",
    },
  ];

  const officeaddressesasiapacific = [
    {
      country: "India",
      company: "Kapil technologies Pvt Ltd",
      altercompany: "( KCS Software Solutions Pvt Ltd )",
      address: `Kapil Kavuri Hub, Nanakramguda, Gachibowli, Hyderabad`,
      pincode: "Telangana - 500032",
      email: "info@kapiltech.com",
    },
    {
      country: "Singapore",
      company: "Kapil Technologies Pte Ltd",
      address: `#13-01, 105 Cecil Street,`,
      pincode: "The Octagon - 069534",
      email: "info@kapiltech.com",
    },
    {
      country: "Thailand",
      company: "c/o Business Systemation Solution",
      address: `7/3 Moo. 7 Bangna Trad Rd., Bangchalong, Bangplee,`,
      pincode: "Samutprakarn - 105040",
      email: "info@kcs-tech.com",
    },
    {
      country: "Sri Lanka",
      company: "c/o Kraey Globe Limited",
      address: `39/10, Rampart Road, Ethul Kotte,`,
      pincode: "Kotte - 10100",
      email: "info@kcs-tech.com",
    },
    {
      country: "Fiji",
      company: "c/o Alpha Technologies",
      address: `58 Vomo Street, Lautoka,`,
      pincode: "Fiji Islands - 00000",
      email: "info@kcs-tech.com",
    },
    {
      country: "Indonesia",
      company: "PT KCS Technologies Indonesia",
      address: `BSD Green Office Park 1, 6th Floor Jl.
       BSD Green Office Park, BSD City,`,
      pincode: "Tangerang - 15345",
      email: "info@ptkcs.com",
    },

    {
      country: "Malaysia",
      company: "Relevant Business Solutions SDN. BHD.",
      address: `Unit A805,No.1,Lorong Ultra B,

Off Jalan Utara,

46200 Petaling Jaya,`,
      pincode: "Selangor - 528225",
      email: "info@relevantsolution.com.my",
    },
    {
      country: "Combodia",
      company: "c/o Combodiasoft",
      address: `Building #101A, St. 289,

Sangkat Boeung Kak I,

Khan Toul Kok,`,
      pincode: "Phnom Penh - 120407",
      email: "info@kcs-tech.com",
    },
 

  ];

  const officeaddressesmiddleeast = [
    {
      country: "United Arab Emirates",
      company: "Kapil Tech Dubai",
      address: `Imperial Building,

Silicon Oasis,`,
      pincode: "Dubai - 341034",
      email: "info@kcs-tech.com",
    },
    
  ];
  useScrollAnimation();

  return (
    <div>
      <section className="">
        <div className=" contant_main"></div>

        <div
          className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-10 xl:mx-40 mx-3 md:mx-6 relative  -top-[7rem]
"
        >
          {/* Form Section */}
          <div className="lg:col-span-2 col-span-1 shadow-lg p-8 bg-[#fff] rounded-md animate-up">
            <h2 className="text-darkblue text-2xl font-bold mb-2">
              Contact Us
            </h2>
            <h3 className="text-lg text-gray-700 mb-4">
              Tell us a Little About Yourself
            </h3>
            <form onSubmit={handleSubmit}>
              {/* Form Fields */}
              <div className="grid md:grid-cols-2 gap-6 mt-5">
                <div className="w-full">
                  <input
                    type="text"
                    name="fullname"
                    id="name"
                    value={formData.fullname}
                    className="contact_form w-full p-3 rounded-md shadow-sm border"
                    placeholder="Full Name"
                    onChange={handleChange}
                  />

                  <div className="h-3">
                    {errors.fullname && (
                      <span className="text-xs text-red ">
                        {errors.fullname}
                      </span>
                    )}
                  </div>
                </div>
                <div className="w-full">
                  <input
                    type="text"
                    name="company"
                    id="company"
                    className="contact_form w-full p-3 rounded-md shadow-sm border"
                    placeholder="Company Name"
                    value={formData.company}
                    onChange={handleChange}
                  />

                  <div className="h-3">
                    {errors.company && (
                      <span className="text-xs text-red ">
                        {errors.company}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid md:grid-cols-2 gap-6 mt-5">
                <div className="w-full">
                  <input
                    type="email"
                    id="email"
                    name="workemail"
                    className="contact_form w-full p-3 rounded-md shadow-sm border"
                    value={formData.workemail}
                    placeholder="Work Email"
                    onChange={handleChange}
                  />
                  <div className="h-3">
                    {errors.workemail && (
                      <span className="text-xs text-red ">
                        {errors.workemail}
                      </span>
                    )}
                  </div>
                </div>
                <div className="w-full">
                  <select
                    id="country"
                    name="country"
                    className="contact_form w-full p-3 rounded-md shadow-sm border"
                    value={formData.country}
                    onChange={handleChange}
                  >
                    <option value="" disabled selected>
                      Country
                    </option>
                    <option>Canada</option>
                    <option>France</option>
                    <option>Germany</option>
                    {/* Add more countries as needed */}
                  </select>
                  <div className="h-3">
                    {errors.country && (
                      <span className="text-xs text-red ">
                        {errors.country}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid md:grid-cols-2 gap-6 mt-5">
                <div className="w-full">
                  <select
                    id="countrycode"
                    name="countrycode"
                    className="contact_form w-full p-3 rounded-md shadow-sm border"
                    value={formData.countrycode}
                    onChange={handleChange}
                  >
                    <option value="" disabled selected>
                      Country Code
                    </option>
                    <option>435345</option>
                    <option>345345345</option>
                    <option>34534534543</option>
                    {/* Add more countries as needed */}
                  </select>
                  <div className="h-3">
                    {errors.countrycode && (
                      <span className="text-xs text-red ">
                        {errors.countrycode}
                      </span>
                    )}
                  </div>
                </div>
                <div className="w-full">
                  <input
                    type="number" 
                    name="mobilenumber"
                    id="mobile-number"
                    className="contact_form w-full p-3 rounded-md shadow-sm border"
                    value={formData.mobilenumber}
                    placeholder="Mobile"
                    onChange={handleChange}
                  />
                  <div className="h-3">
                    {errors.mobilenumber && (
                      <span className="text-xs text-red ">
                        {errors.mobilenumber}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid md:grid-cols-2 gap-6 mt-5">
                <div className="w-full mb-2">
                  <input
                    type="text"
                    id="nicheindustry"
                    name="nicheindustry"
                    className="contact_form w-full p-3 rounded-md shadow-sm border"
                    placeholder="Niche Industry"
                    value={formData.nicheindustry}
                    onChange={handleChange}
                  />
                  <div className="h-3">
                    {errors.nicheindustry && (
                      <span className="text-xs text-red ">
                        {errors.nicheindustry}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full mb-5 mt-5">
                <textarea
                  id="message"
                  rows="4"
                  name="about"
                  placeholder="Please tell us how we can help you..."
                  value={formData.about}
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  onChange={handleChange}
                ></textarea>
                <div className="h-3">
                  {errors.about && (
                    <span className="text-xs text-red ">{errors.about}</span>
                  )}
                </div>
              </div>
              <div className="flex items-center ">
                <input
                  id="agreement"
                  type="checkbox"
                  name="agreement"
                  className="w-4 h-4 text-blue-600 bg-gray-200 border-gray-300 rounded focus:ring-blue-500"
                  checked={formData.agreement}
                  onChange={handleChange}
                />
                <label
                  htmlFor="agreement"
                  className="ml-2 text-sm font-medium text-gray-900"
                >
                  By submitting this form, you acknowledge that Kapil
                  Technologies may use your personal information for marketing
                  communications as outlined in its privacy policy.
                  <span className="text-red">*</span>
                </label>
              </div>
              <div className="h-3">
                {errors.agreement && (
                  <span className="text-xs text-red ">{errors.agreement}</span>
                )}
              </div>
              <button
                type="submit"
                className="border mt-7 bg-[#4e8ecb] rounded-md py-2 px-4 text-[#fff] mb-2 "
              >
                Submit
              </button>
            </form>
          </div>

          {/* Content Section */}
          <div className="shadow-lg p-8 bg-[#fff] rounded-md animate-up">
            <h2 className="text-darkblue text-2xl font-bold mb-4">
              Connect with Us
            </h2>
            <p className="text-gray-600 mb-8">
              To ensure we meet your needs effectively, we value any specific
              details you can share about your unique requirements or
              challenges.
            </p>
            {/* Contact Details */}
            <div className="flex items-center mb-5">
              <svg
                className="w-6 h-6 text-blue-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                />
              </svg>
              <span className="text-gray-700 ml-3">info@kcs-tech.com</span>
            </div>
            <div className="flex items-center mb-5">
              <svg
                className="w-6 h-6 text-blue-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75A2.25 2.25 0 0 0 15.75 1.5h-2.25v2.25h-3V1.5z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M15 12H9"
                />
              </svg>
              <span className="text-gray-700 ml-3">+1 6099345788</span>
            </div>
            <div className="flex items-center mb-5">
              <svg
                className="w-6 h-6 text-blue-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75A2.25 2.25 0 0 0 15.75 1.5h-2.25v2.25h-3V1.5z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M15 12H9"
                />
              </svg>
              <span className="text-gray-700 ml-3">+91 8889992186</span>
            </div>
            <div className="flex items-center mb-5">
              <svg
                className="w-6 h-6 text-blue-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M8.25 6.75v1.5m0 1.5V15m0 1.5v1.5m0-13.5v-1.5a2.25 2.25 0 0 1 2.25-2.25h3.75A2.25 2.25 0 0 1 16.5 4.5v1.5m-8.25 0h8.25"
                />
              </svg>
              <span className="text-gray-700 ml-3">
                123 Main Street, Anytown, USA
              </span>
            </div>
            <p className="text-gray-600">
              We typically respond within one business day. Your satisfaction is
              our top priority.
            </p>
          </div>
        </div>
      </section>
      <section className="bg-[#f5f5f5]">
        <div className="xl:mx-40 mx-3 md:mx-6">
          <h2 className="text-4xl pb-8 pt-4 animate-up">Our Offices</h2>
          <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-b-[#d1d5db] dark:text-gray-400">
            <li
              onClick={() => handleNavLinkClick("america")}
              className={`cursor-pointer w-full sm:w-1/6 pt-3 pb-4 text-sm font-[500] text-[#0c0a09] ${
                activeLink === "america"
                  ? "border-x border-x-[#d1d5db] border-t border-t-[#d1d5db] text-orange-600"
                  : ""
              }`}
            >
              <div className="flex flex-col items-center">
                <span className="text-2xl mb-3">America</span>
                <img className="hidden sm:block" src={america} alt="america" />
              </div>
            </li>
            <li
              onClick={() => handleNavLinkClick("asiapacific")}
              className={`cursor-pointer w-full sm:w-1/6 pt-3 pb-4 text-sm font-[500] text-[#0c0a09] ${
                activeLink === "asiapacific"
                  ? "border-x border-x-[#d1d5db] border-t border-t-[#d1d5db] text-orange-600"
                  : ""
              }`}
            >
              <div className="flex flex-col items-center">
                <span className="text-2xl mb-3">Asia Pacific</span>
                <img
                  className="hidden sm:block"
                  src={asia_pacific}
                  alt="asia_pacific"
                />
              </div>
            </li>
           
            <li
              onClick={() => handleNavLinkClick("middleeast")}
              className={`cursor-pointer w-full sm:w-1/6 pt-3 pb-4 text-sm font-[500] text-[#0c0a09] ${
                activeLink === "middleeast"
                  ? "border-x border-x-[#d1d5db] border-t border-t-[#d1d5db] text-orange-600"
                  : ""
              }`}
            >
              <div className="flex flex-col items-center justify-center">
                <span className="text-2xl mb-3">Middle East</span>
                <img
                  className="hidden sm:block"
                  src={middle_east}
                  alt="middle_east"
                />
              </div>
            </li>
          </ul>

          {/* Office Address Content Based on Active Link */}
          {activeLink === "america" && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 py-5">
              {officeaddressesamerica.map((address, index) => (
                <div key={index} className="col-span-1 mt-5 ">
                  <h3 className="text-2xl text-[#4d4d4f] font-semibold">
                    {address.country}
                  </h3>
                  <h4 className="font-normal text-lg text-bluecolor">
                    {address.company}
                  </h4>
                  <span className="text-sm text-[#9ca3af] block mb-1">
                    {address.address}
                  </span>
                  <span className="text-sm text-[#9ca3af] block">
                    {address.pincode}
                  </span>

                  <p className="flex gap-2 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                      />
                    </svg>
                 
                      <span className="text-sm hover:text-bluecolor">
                        {address.email}
                      </span>
                   
                  </p>
                </div>
              ))}
            </div>
          )}
          {activeLink === "asiapacific" && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 py-5">
              {officeaddressesasiapacific.map((address, index) => (
                <div key={index} className="col-span-1 mt-5 ">
                  <h3 className="text-2xl text-[#4d4d4f] font-semibold">
                    {address.country}
                  </h3>
                  <h4 className="font-normal text-lg text-bluecolor">
                    {address.company}
                  </h4>
                  <h6 className="text-xs text-[#9ca3af] mb-1">
                    {address.altercompany}
                  </h6>
                  <span className="text-sm text-[#9ca3af] block mb-1">
                    {address.address}
                  </span>
                  <span className="text-sm text-[#9ca3af] block">
                    {address.pincode}
                  </span>

                  <p className="flex gap-2 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                      />
                    </svg>
                 
                      <span className="text-sm hover:text-bluecolor">
                        {address.email}
                      </span>
                    
                  </p>
                </div>
              ))}
            </div>
          )}

          {activeLink === "middleeast" && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 py-5">
              {officeaddressesmiddleeast.map((address, index) => (
              <div key={index} className="col-span-1 mt-5 ">
              <h3 className="text-2xl text-[#4d4d4f] font-semibold">
                {address.country}
              </h3>
              <h4 className="font-normal text-lg text-bluecolor">
                {address.company}
              </h4>
              <h6 className="text-xs text-[#9ca3af] mb-1">
                {address.altercompany}
              </h6>
              <span className="text-sm text-[#9ca3af] block mb-1">
                {address.address}
              </span>
              <span className="text-sm text-[#9ca3af] block">
                {address.pincode}
              </span>

              <p className="flex gap-2 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                  />
                </svg>
             
                  <span className="text-sm hover:text-bluecolor">
                    {address.email}
                  </span>
                
              </p>
            </div>
              ))}
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Contact;
