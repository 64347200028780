
import { Link } from "react-router-dom";
const Partners_Allianzes = ({ partners_allianzes_Links,setOpen , setActive,  linkClicked,}) => {
  return (
    <>
      <div className="absolute top-0  xl:top-10  xl:block hidden z-10 shadow-xl xl:w-60">
        <div className="bg-[#fff] p-5 ">
          <div>
            {partners_allianzes_Links.partners_allianzes_1?.links?.subLink1.map(
              (item) => (
                <li className="no-underline list-none mb-2 capitalize">
                  <Link
                    to={item.link}
                    className="text-sm  text-gray-400 my-2.5 hover:text-[#4A3AFF]"
                    onClick={() => {setActive("")
                      linkClicked()}
                    }
                  >
                    {item.name}
                  </Link>
                </li>
              )
            )}{" "}
          </div>
        </div>
      </div>

      {/* mobile screen*/}

      <div className="xl:hidden block">
        <div>
          {partners_allianzes_Links.partners_allianzes_1?.links?.subLink1.map(
            (item) => (
              <li className="no-underline list-none mb-2">
                <Link
                  to={item.link}
                  className="text-sm text-gray-400 my-2.5 hover:text-[#4A3AFF] ml-5"
                  onClick={() => setOpen("")}
                >
                  {item.name}
                </Link>
              </li>
            )
          )}{" "}
        </div>
      </div>
    </>
  );
};

export default Partners_Allianzes;
