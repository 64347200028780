import React from "react";
import Girls from "../../../Assets/girls.png";
import consulting from "../../../Assets/pngimg3.jpg";
import family_picture from "../../../Assets/pngimage2.png";
import { Link } from "react-router-dom";
import { useScrollAnimation } from "../../../utils/Utils";

const Manufacturing = () => {
  useScrollAnimation();
  return (
    <div>
      <section className="bg-[#efefef] py-7">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-7 lg:mx-40 mx-3">
          <div className="col-span-1 my-auto">
            <h2 className="text-3xl text-darkblue pb-5"> Manufacturing</h2>
            <h3 className="text-xl text-darkblue pb-5">
              {" "}
              Balancing efficiency and resilience in a new digital era
            </h3>
            <p className="text-md pb-4">
              {" "}
              Kapil Technologies helps manufacturing companies build agile,
              resilient supply chains, providing innovative solutions to address
              new market realities and meet customer needs.
            </p>
            {/* <Link className="text-lg hover:underline hover:text-bluecolor">
              {" "}
              News and client stories
              <span className="text-3xl "> &#8594;</span>{" "}
            </Link> */}
          </div>
          <div className="col-span-1">
            <img src={Girls} alt="client stories"/>
          </div>
        </div>
      </section>
      <section className="py-7 bg-[#fff]">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 lg:mx-40 mx-3">
          <div className="col-span-1 text-center animate-up">
            <h2 className="text-5xl text-darkblue pb-2"> 5,500</h2>
            <p className=" text-[#a3a3a3] text-sm">
              industry consultants across the globe
            </p>
          </div>
          <div className="col-span-1 text-center animate-up">
            <h2 className="text-5xl text-darkblue pb-2"> Top 10</h2>
            <p className=" text-[#a3a3a3] text-sm">
              industry consultants across the globe
            </p>
          </div>
          <div className="col-span-1 text-center animate-up">
            <h2 className="text-5xl text-darkblue pb-2"> 2,800</h2>
            <p className=" text-[#a3a3a3] text-sm">
              industry consultants across the globe
            </p>
          </div>
        </div>
      </section>
      <section className="py-7 bg-[#efefef]">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 lg:mx-40 mx-3  gap-8">
          <div className="col-span-1 animate-up">
          <div class="video-container ">
          <iframe
    width="650"
    height="315"
    src="https://www.youtube.com/embed/oTIJunBa6MA?si=g5bXfPjjA7J__65l"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    referrerpolicy="strict-origin-when-cross-origin"
    allowfullscreen
  ></iframe>
</div>
          </div>
          <div className="col-span-1 my-auto animate-up">
            <h4 className="text-xl pb-3 text-bluecolor">
              {" "}
              It’s the dawn of a new era in manufacturing
            </h4>
            <p>
              Digitization is reimagining the way you produce, sell and deliver
              across your entire value chain. Industry 4.0 and new technologies
              offer the opportunity to do business in a profoundly different
              way—as a connected, adaptive and sustainable manufacturer,
              integrated with your entire ecosystem. For over four decades, we
              have helped leading manufacturers navigate and seize the
              opportunities of change. With deep industry and technology
              expertise, our consultants work with you to improve business
              agility so you can drive efficiencies and reduce costs as you
              continue to advance your strategic goals.{" "}
            </p>
          </div>
        </div>
      </section>
      <section className="bg-[#fff] py-7">
        <div className="lg:mx-40 mx-3 ">
          <div className="grid grid-col-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-4 ">
            <div className="col-span-1 lg:border-r border-r-gray-400 px-4 animate-up">
              <h1 className="text-xl  text-bluecolor py-3"> Insights</h1>
              <p className="text-sm pb-5">
                We provide a global antenna based on facts, not hype, to help
                improve returns on your IT and business investments.
              </p>
              <p className="text-sm">
                We provide a global antenna based on facts, not hype, to help
                improve returns on your IT and business investments.
              </p>
              <div class="px-6 pt-4 pb-2">
                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 border border-black ">
                  #photography
                </span>
                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 border border-black ">
                  #travel
                </span>
                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 border border-black ">
                  #winter
                </span>
              </div>
            </div>
            <div className="col-span-1 lg:border-r border-r-gray-400 px-4 animate-up">
              <h1 className="text-xl  text-bluecolor py-3"> Insights</h1>
              <p className="text-sm pb-5">
                We provide a global antenna based on facts, not hype, to help
                improve returns on your IT and business investments.
              </p>
              <p className="text-sm">
                We provide a global antenna based on facts, not hype, to help
                improve returns on your IT and business investments.
              </p>
              <div class="px-6 pt-4 pb-2">
                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 border border-black ">
                  #photography
                </span>
                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 border border-black ">
                  #travel
                </span>
                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 border border-black ">
                  #winter
                </span>
              </div>
            </div>
            <div className="col-span-1 lg:border-r border-r-gray-400 px-4 animate-up">
              <h1 className="text-xl  text-bluecolor py-3"> Insights</h1>
              <p className="text-sm pb-5">
                We provide a global antenna based on facts, not hype, to help
                improve returns on your IT and business investments.
              </p>
              <p className="text-sm">
                We provide a global antenna based on facts, not hype, to help
                improve returns on your IT and business investments.
              </p>
              <div class="px-6 pt-4 pb-2">
                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 border border-black ">
                  #photography
                </span>
                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 border border-black ">
                  #travel
                </span>
                <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 border border-black ">
                  #winter
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-7 bg-[#efefef]">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 lg:mx-40 mx-3 gap-10 ">
          <div className="col-span-1 ">
            <h3 className="text-3xl text-darkblue pb-4 animate-up">
              Kapil Technologies Voice of Our Clients: Solid data foundations
              are needed to enable innovation, diversification and efficiency{" "}
            </h3>
            <p className="pb-4 animate-up">
              Amid economic uncertainty, manufacturers are looking to optimize
              costs and diversify to survive and thrive. The sustainability
              imperative grows, while artificial intelligence (AI) promises to
              accelerate transformation efforts.
            </p>
            <p className="animate-up">
              As part of the 2024 Kapil Technologies Voice of Our Clients, we
              met with 202 manufacturing executives to understand their top
              priorities and how they are preparing for and adapting to key
              trends shaping their organizations.
            </p>
          </div>
          <div className="col-span-1 animate-up">
            <img className="" src={consulting} alt="clients" />
          </div>
        </div>
      </section>
      <section className="py-7 bg-[#fff]">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 lg:mx-40 mx-3 gap-10 ">
          <div className="col-span-1 animate-up">
            <img className="" src={family_picture} alt="manufacturing_source" />
          </div>
          <div className="col-span-1">
            <h3 className="text-3xl text-darkblue pb-4 animate-up">
              Driving the future of possibility in automotive manufacturing{" "}
            </h3>
            <p className="pb-4 animate-up">
              Macro trends like climate change, supply chain reconfiguration,
              and a shift in the world economic order have led automotive
              companies to rethink their strategies. In addition, stricter
              regulations like Germany’s Supply Chain Act and the acceleration
              of sustainable mobility continue to drive the whole industry’s
              transformation.
            </p>
            <p className="animate-up">
              As the automotive industry reinvents itself, we help our
              automotive clients secure their place in the new digital ecosystem
              with services and solutions across each point of the manufacturing
              and customer journey.
            </p>
          </div>
        </div>
      </section>
      <section className="py-7 bg-[#efefef]">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 lg:mx-40 mx-3 gap-10 ">
          <div className="col-span-1">
            <h3 className="text-3xl text-darkblue pb-4 animate-up">
              Transforming data into actionable insights to drive smart,
              sustainable and resilient manufacturing operations
            </h3>
            <p className="pb-4 animate-up">
              As manufacturing environments become more complex, forward-looking
              manufacturers see extraordinary benefits in using data to meet new
              market realities, balance competing priorities and advance digital
              transformation. Kapil Technologies's Data-Driven Manufacturing
              offering is a unique four-step approach that combines strategy,
              governance and the human aspect of change to implement data
              solutions that help you realize your strategic vision.
            </p>
          </div>
          <div className="col-span-1 animate-up">
            <img className="" src={consulting} alt="Transformation data" />
          </div>
        </div>
      </section>
   
    </div>
  );
};

export default Manufacturing;
